// This SVG asset is displayed in the global header.

import React from 'react';
import styled from 'styled-components';

export const AllenNLPLogo = ({ className }) => (
    <SVG
        className={className}
        width={145}
        height={25}
        viewBox="0 0 145 25"
        xmlns="http://www.w3.org/2000/svg">
        <title>AllenNLP</title>
        <path d="M22.5718 0H27.0668V18.7533C27.0668 19.3817 27.2118 19.9133 27.5501 20.2517C27.8401 20.59 28.3234 20.7833 28.9034 20.7833C29.1934 20.7833 29.4834 20.735 29.7734 20.6383C30.1118 20.5417 30.4018 20.445 30.6918 20.3L31.2718 23.6833C30.6918 23.9733 29.9668 24.215 29.1451 24.36C28.3234 24.505 27.5984 24.6017 26.9701 24.6017C25.5684 24.6017 24.5051 24.215 23.7318 23.49C22.9584 22.765 22.5718 21.7017 22.5718 20.3C22.5718 20.3 22.5718 0 22.5718 0Z" />
        <path d="M32.2867 0H36.7817V18.7533C36.7817 19.3817 36.9267 19.9133 37.2651 20.2517C37.5551 20.59 38.0384 20.7833 38.6184 20.7833C38.9084 20.7833 39.1984 20.735 39.4884 20.6383C39.8267 20.5417 40.1167 20.445 40.4067 20.3L40.9867 23.6833C40.4067 23.9733 39.6817 24.215 38.8601 24.36C38.0384 24.505 37.3134 24.6017 36.6851 24.6017C35.2834 24.6017 34.2201 24.215 33.4467 23.49C32.6734 22.765 32.2867 21.7017 32.2867 20.3C32.2867 20.3 32.2867 0 32.2867 0Z" />
        <path d="M50.1701 24.7466C48.7684 24.7466 47.5118 24.5049 46.3518 24.0216C45.2401 23.5383 44.2251 22.9099 43.4518 22.0883C42.6301 21.2666 42.0501 20.2999 41.6151 19.2366C41.1801 18.1249 40.9868 17.0133 40.9868 15.8049C40.9868 14.5483 41.1801 13.3883 41.6151 12.2766C42.0501 11.1649 42.6301 10.1983 43.4518 9.37659C44.2734 8.55492 45.2401 7.87826 46.3518 7.39492C47.5118 6.91159 48.7684 6.66992 50.2184 6.66992C51.6201 6.66992 52.9251 6.91159 54.0368 7.39492C55.1484 7.87826 56.1151 8.55492 56.8884 9.37659C57.6618 10.1983 58.2901 11.1649 58.6768 12.2283C59.1118 13.3399 59.3051 14.4516 59.3051 15.6116C59.3051 15.9016 59.3051 16.1916 59.3051 16.4333C59.3051 16.7233 59.2568 16.9166 59.2084 17.1099H45.6751C45.7234 17.7866 45.9168 18.4149 46.1584 18.9466C46.4484 19.4783 46.7868 19.9133 47.1734 20.2999C47.6084 20.6866 48.0918 20.9766 48.6234 21.1699C49.1551 21.3633 49.7351 21.4599 50.3151 21.4599C51.1851 21.4599 52.0551 21.2666 52.8284 20.8316C53.6018 20.3966 54.1818 19.8166 54.4234 19.0916L58.2418 20.1549C57.6134 21.5083 56.5501 22.5716 55.1484 23.4416C53.8434 24.3116 52.1518 24.7466 50.1701 24.7466ZM54.7134 14.1616C54.6168 12.8566 54.1334 11.7933 53.2634 11.0199C52.3934 10.2466 51.3784 9.81159 50.1218 9.81159C49.5418 9.81159 48.9618 9.90826 48.4301 10.1499C47.8984 10.3433 47.4634 10.6333 47.0284 11.0199C46.6418 11.4066 46.3034 11.8416 46.0618 12.3733C45.8201 12.9049 45.6751 13.4849 45.6268 14.1616H54.7134Z" />
        <path d="M77.4784 24.4082H72.9834V14.5966C72.9834 13.1949 72.7417 12.1799 72.2584 11.5032C71.7751 10.8749 71.0984 10.5366 70.2284 10.5366C69.7934 10.5366 69.3101 10.6332 68.8751 10.8266C68.3917 11.0199 67.9567 11.2616 67.5701 11.5999C67.1351 11.9382 66.7967 12.3249 66.4584 12.7599C66.1201 13.1949 65.8784 13.6782 65.7334 14.2099V24.4082H61.2384V6.91158H65.2984V10.1499C65.9267 9.03825 66.8934 8.16825 68.1017 7.53992C69.3101 6.91158 70.7117 6.62158 72.2584 6.62158C73.3701 6.62158 74.2401 6.81492 74.9167 7.20158C75.5934 7.58825 76.1251 8.11992 76.5117 8.74825C76.8984 9.37658 77.1401 10.1499 77.2851 10.9716C77.4301 11.7932 77.4784 12.6149 77.4784 13.4849V24.4082Z" />
        <path d="M84.2451 2.90009V24.4084H83.0851V0.725091H83.9551L101.403 22.5718V0.676758H102.563V24.3601H101.452L84.2451 2.90009Z" />
        <path d="M108.073 24.4084V0.725098H109.233V23.3451H123.733V24.4084H108.073Z" />
        <path d="M128.518 24.4084V0.725098H138.233C139.2 0.725098 140.118 0.918431 140.94 1.35343C141.762 1.78843 142.487 2.3201 143.067 2.99676C143.647 3.67343 144.13 4.44676 144.468 5.31676C144.807 6.18676 145 7.05676 145 7.9751C145 8.89343 144.855 9.81176 144.517 10.6818C144.178 11.5518 143.743 12.3251 143.163 13.0018C142.583 13.6784 141.907 14.2101 141.085 14.6451C140.263 15.0801 139.393 15.2734 138.427 15.2734H129.678V24.4084H128.518ZM129.678 14.1618H138.378C139.2 14.1618 139.973 13.9684 140.65 13.6301C141.327 13.2918 141.907 12.8084 142.342 12.2284C142.825 11.6484 143.163 10.9718 143.405 10.2468C143.647 9.52176 143.792 8.74843 143.792 7.9751C143.792 7.15343 143.647 6.3801 143.357 5.6551C143.067 4.9301 142.632 4.25343 142.148 3.67343C141.617 3.09343 141.037 2.65843 140.36 2.3201C139.683 1.93343 138.958 1.7401 138.185 1.7401H129.678V14.1618Z" />
        <path d="M21.5083 4.88167C19.5267 5.99334 17.5933 7.20167 15.7567 8.45834L12.8083 0.0483398H8.50667L0.29 23.8283L0 24.6017H4.785C4.785 24.6017 4.83333 24.5533 4.88167 24.4567C7.25 20.7833 10.0533 17.3517 13.0983 14.21L16.53 24.6017H21.315L16.5783 10.9233C18.1733 9.52167 19.7683 8.21667 21.5083 7.00834V4.88167ZM7.975 14.8867L10.6817 6.62167L12.18 11.165C10.73 12.3733 9.32833 13.5817 7.975 14.8867Z" />
    </SVG>
);

const SVG = styled.svg`
    fill: ${({ theme }) => theme.color.N10};
`;
