module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":790,"linkImagesToOriginal":true,"sizeByPixelDensity":false,"showCaptions":true,"quality":80,"withWebp":{"quality":80}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"A Guide to Natural Language Processing With AllenNLP","short_name":"A Guide to Natural Language Processing With AllenNLP","start_url":"/","display":"minimal-ui","legacy":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
