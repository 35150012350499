// This SVG asset displays a "tools" icon intended for Practical Tasks section

import React from 'react';
import { PartIcon } from './';

export const ToolsIcon = ({ className, size }) => (
    <PartIcon>
        <path d="M26.0625 10H10.4219C9.56875 10 9 10.5677 9 11.4194V96.5806C9 97.4323 9.56875 98 10.4219 98H26.0625C26.9156 98 27.4844 97.4323 27.4844 96.5806V11.4194C27.4844 10.5677 26.9156 10 26.0625 10ZM24.6406 15.6774H20.375V18.5161H24.6406V21.3548H20.375V24.1935H24.6406V27.0323H20.375V29.871H24.6406V32.7097H20.375V35.5484H24.6406V38.3871H20.375V41.2258H24.6406V44.0645H20.375V46.9032H24.6406V49.7419H20.375V52.5806H24.6406V55.4194H20.375V58.2581H24.6406V61.0968H20.375V63.9355H24.6406V66.7742H20.375V69.6129H24.6406V72.4516H20.375V75.2903H24.6406V78.129H20.375V80.9677H24.6406V83.8064H20.375V86.6452H24.6406V89.4839H20.375V92.3226H24.6406V95.1613H11.8438V12.8387H24.6406V15.6774Z" />
        <path d="M39.4281 27.8839C39.1437 27.1742 38.2906 26.8903 37.5797 27.1742C37.2953 27.3161 37.0109 27.6 36.8688 27.8839L33.3141 36.4C33.1719 36.542 33.1719 36.8258 33.1719 36.9678V96.5807C33.1719 97.4323 33.7406 98 34.5938 98H41.7031C42.5563 98 43.125 97.4323 43.125 96.5807V36.9678C43.125 36.8258 43.125 36.542 42.9828 36.4L39.4281 27.8839ZM38.1484 32.142L39.5703 35.5484H36.7266L38.1484 32.142ZM36.0156 85.2258V38.3871H40.2812V85.2258H36.0156ZM40.2812 88.0645V89.4839H36.0156V88.0645H40.2812ZM36.0156 95.1613V92.3226H40.2812V95.1613H36.0156Z" />
        <path d="M51.2297 48.7485C50.8031 48.3226 50.2344 48.1807 49.6656 48.4646C49.0969 48.6065 48.8125 49.1743 48.8125 49.742V96.5807C48.8125 97.4323 49.3812 98.0001 50.2344 98.0001H97.1562C97.725 98.0001 98.2938 97.7162 98.4359 97.1485C98.7203 96.5807 98.5781 96.013 98.1516 95.5872L51.2297 48.7485ZM51.6562 95.1614V53.1485L93.7437 95.1614H51.6562Z" />
        <path d="M63.7422 90.1936C67.7234 90.1936 70.8516 87.071 70.8516 83.0968C70.8516 79.1226 67.7234 76 63.7422 76C59.7609 76 56.6328 79.1226 56.6328 83.0968C56.6328 87.071 59.7609 90.1936 63.7422 90.1936ZM63.7422 78.8387C66.1594 78.8387 68.0078 80.6839 68.0078 83.0968C68.0078 85.5097 66.1594 87.3548 63.7422 87.3548C61.325 87.3548 59.4766 85.5097 59.4766 83.0968C59.4766 80.6839 61.325 78.8387 63.7422 78.8387Z" />
        <path d="M34.5938 19.9355H50.2344C51.0875 19.9355 51.6562 19.3677 51.6562 18.5161V11.4194C51.6562 10.5677 51.0875 10 50.2344 10H38.0062C35.3047 10 33.1719 12.129 33.1719 14.8258V18.5161C33.1719 19.3677 33.7406 19.9355 34.5938 19.9355ZM48.8125 17.0968H41.7031V12.8387H48.8125V17.0968ZM36.0156 14.8258C36.0156 13.6903 36.8687 12.8387 38.0062 12.8387H38.8594V17.0968H36.0156V14.8258Z" />
        <path d="M71.5625 31.2903H65.875C65.0219 31.2903 64.4531 31.858 64.4531 32.7096C64.4531 33.5613 65.0219 34.129 65.875 34.129H71.5625C72.4156 34.129 72.9844 33.5613 72.9844 32.7096C72.9844 31.858 72.4156 31.2903 71.5625 31.2903Z" />
        <path d="M94.3125 31.2903H88.4828C87.7719 26.4645 83.6484 22.7742 78.6719 22.7742H67.2969C61.7516 22.7742 57.3438 27.1742 57.3438 32.7097C57.3438 38.2451 61.7516 42.6451 67.2969 42.6451H78.6719C83.6484 42.6451 87.7719 38.9548 88.4828 34.129H94.3125C95.1656 34.129 95.7344 34.6968 95.7344 35.5484V44.0645C95.7344 44.9161 95.1656 45.4838 94.3125 45.4838H72.9844C70.5672 45.4838 68.7188 47.329 68.7188 49.7419C68.7188 52.1548 70.5672 54 72.9844 54H90.0469C90.9 54 91.4688 54.5677 91.4688 55.4193C91.4688 56.2709 90.9 56.8387 90.0469 56.8387H77.25C74.8328 56.8387 72.9844 58.6838 72.9844 61.0968C72.9844 63.5097 74.8328 65.3548 77.25 65.3548H85.7812C86.6344 65.3548 87.2031 65.9226 87.2031 66.7742V73.8709C87.2031 74.7226 87.7719 75.2903 88.625 75.2903C89.4781 75.2903 90.0469 74.7226 90.0469 73.8709V66.7742C90.0469 64.3613 88.1984 62.5161 85.7812 62.5161H77.25C76.3969 62.5161 75.8281 61.9484 75.8281 61.0968C75.8281 60.2451 76.3969 59.6774 77.25 59.6774H90.0469C92.4641 59.6774 94.3125 57.8322 94.3125 55.4193C94.3125 53.0064 92.4641 51.1613 90.0469 51.1613H72.9844C72.1312 51.1613 71.5625 50.5935 71.5625 49.7419C71.5625 48.8903 72.1312 48.3226 72.9844 48.3226H94.3125C96.7297 48.3226 98.5781 46.4774 98.5781 44.0645V35.5484C98.5781 33.1355 96.7297 31.2903 94.3125 31.2903ZM78.6719 39.8064H67.2969C63.3156 39.8064 60.1875 36.6838 60.1875 32.7097C60.1875 28.7355 63.3156 25.6129 67.2969 25.6129H78.6719C82.6531 25.6129 85.7812 28.7355 85.7812 32.7097C85.7812 36.6838 82.6531 39.8064 78.6719 39.8064Z" />
        <path d="M100 10H97.1562V12.8387H100V10Z" />
        <path d="M100 15.6774H97.1562V18.5161H100V15.6774Z" />
        <path d="M94.3125 10H91.4688V12.8387H94.3125V10Z" />
        <path d="M94.3125 15.6774H91.4688V18.5161H94.3125V15.6774Z" />
        <path d="M88.625 10H85.7812V12.8387H88.625V10Z" />
        <path d="M82.9375 10H80.0938V12.8387H82.9375V10Z" />
        <path d="M100 21.3549H97.1562V24.1936H100V21.3549Z" />
        <path d="M90.0469 78.129H87.2031V80.9678H90.0469V78.129Z" />
    </PartIcon>
);
