// This SVG asset displays a "cube" icon intended for Abstractions, Design, and Testing section

import React from 'react';
import { PartIcon } from './';

export const CubeIcon = ({ className, size }) => (
    <PartIcon>
        <path d="M93.8132 28.632L54.8311 6.36C53.8803 5.88 52.8345 5.88 51.8837 6.36L12.5213 28.92C12.3311 29.016 12.0459 29.208 11.8557 29.4C11.7606 29.496 11.6655 29.688 11.4754 29.88C11.3803 29.976 11.2852 30.168 11.1902 30.36C11.0951 30.456 11.0951 30.648 11.0951 30.744C11.0951 30.84 11.0951 30.84 11.0951 30.936C11 31.128 11 31.32 11 31.512V77.688C11 78.744 11.5705 79.8 12.5213 80.28L51.9787 101.592C52.4541 101.784 52.9295 101.976 53.4049 101.976H53.5951C54.0705 101.976 54.5459 101.88 55.0213 101.592L94.4788 80.28C95.4295 79.8 96 78.744 96 77.688V31.512C96.0951 30.072 95.1443 28.92 93.8132 28.632ZM53.4049 10.008L68.5224 18.744L52.1689 28.824L36.7662 19.512L53.4049 10.008ZM31.5369 85.944L14.8982 76.92V56.856L31.5369 65.688V85.944ZM31.5369 63.384L14.8982 54.552V33.24L31.5369 42.744V63.384ZM16.0392 31.512L34.6745 20.76L50.1723 29.976L32.5828 40.92L16.0392 31.512ZM52.5492 97.272L52.3591 97.176L33.6286 87.096V66.84L52.3591 76.728L52.5492 76.824V97.272ZM52.5492 74.616L52.3591 74.52L33.6286 64.632V43.992L52.3591 54.744L52.5492 54.84V74.616ZM53.5 52.92L53.4049 52.824L34.6745 42.168L52.1689 31.32L71.1846 42.648L71.2796 42.744L53.5 52.92ZM71.5649 88.056L54.736 97.176L54.5459 97.272V76.824L54.736 76.728L71.5649 67.8V88.056ZM71.5649 65.304L54.736 74.232L54.5459 74.328V54.648L71.66 44.856V65.304H71.5649ZM73.3714 41.4C73.2763 41.304 73.2763 41.304 73.1812 41.208L54.2606 29.976L70.6141 19.896L90.7707 31.416L90.8658 31.512L73.4664 41.496C73.4664 41.496 73.3714 41.496 73.3714 41.4ZM92.1969 76.92L73.7517 87V66.648L92.1969 56.856V76.92ZM92.1969 54.456L73.7517 64.248V43.704L92.1969 33.048V54.456Z" />
    </PartIcon>
);
