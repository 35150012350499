// This SVG asset displays a "stack" icon intended for Overview section

import React from 'react';
import { PartIcon } from './';

export const StackIcon = ({ className, size }) => (
    <PartIcon>
        <path d="M94.5872 50.9392L85.4679 46.2707C84.1835 45.6223 82.6422 46.141 82 47.4378C81.3578 48.7346 81.8716 50.2908 83.156 50.9392L88.0367 53.4031L54 72.9846L19.9633 53.4031L24.844 50.9392C26.1284 50.2908 26.6422 48.7346 26 47.4378C25.3578 46.141 23.8165 45.6223 22.5321 46.2707L13.4128 50.9392C12.5138 51.3282 12 52.236 12 53.1437C12 54.0515 12.5138 54.9592 13.2844 55.4779L52.7156 78.1718C53.1009 78.4311 53.4862 78.5608 54 78.5608C54.3853 78.5608 54.8991 78.4311 55.2844 78.1718L94.7156 55.4779C95.4862 54.9592 96 54.0515 96 53.1437C96 52.236 95.4862 51.3282 94.5872 50.9392Z" />
        <path d="M94.5872 74.4111L85.4679 69.7426C84.1835 69.0942 82.6422 69.613 82 70.9098C81.3578 72.2065 81.8716 73.7627 83.156 74.4111L88.0367 76.875L54 96.3269L19.9633 76.875L24.844 74.4111C26.1284 73.7627 26.6422 72.2065 26 70.9098C25.3578 69.613 23.8165 69.0942 22.5321 69.7426L13.4128 74.4111C12.5138 74.8001 12 75.7079 12 76.6156C12 77.5234 12.5138 78.4311 13.2844 78.9499L52.7156 101.644C53.1009 101.903 53.4862 102.033 54 102.033C54.3853 102.033 54.8991 101.903 55.2844 101.644L94.7156 78.9499C95.4862 78.4311 96 77.5234 96 76.6156C96 75.7079 95.4862 74.8001 94.5872 74.4111Z" />
        <path d="M94.7156 26.6888L55.156 5.29178C54.3853 4.90274 53.4862 4.90274 52.7156 5.29178L13.4128 26.6888C12.6422 27.0779 12.1284 27.9856 12 28.8934C12 29.8011 12.5138 30.7089 13.2844 31.2276L52.7156 53.9215C53.1009 54.1808 53.4862 54.3105 54 54.3105C54.3853 54.3105 54.8991 54.1808 55.2844 53.9215L94.7156 31.2276C95.4862 30.7089 96 29.9308 96 28.8934C96 27.9856 95.4862 27.2076 94.7156 26.6888Z" />
    </PartIcon>
);
